import logo from "./assets/img/MB-Logo_Zeichenfläche 1.svg";
import funding_logo from "./assets/img/eu_efre_zusatz_recht_transparenz_rgb.jpg";
import "./App.css";

function App() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p className="Contact">
        Michél Bookhahn<br></br>
        Schornsteinfegermeister<br></br>
        Kriemhildstraße 1<br></br>
        10365 Berlin<br></br>
        <br></br>
        Mobil: 0176 724 11 305<br></br>
        Büro: 030 94 30 720<br></br>
        Email: info@bookhahn.de
      </p>
      <div className="Funding">
        <img
          src={funding_logo}
          className="Funding-Img"
          alt="European Regional Development Fund"
        />
        <p className="Funding-Text">
          Unser Meisterbetrieb hat eine Förderung erhalten, die mit Mitteln aus
          dem Europäischen Fonds für die regionale Entwicklung ko-finanziert
          worden ist.
        </p>
      </div>
    </header>
  );
}

export default App;
